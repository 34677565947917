import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import axios from 'axios';

import Activity from '../../profile/tabs/activity';
import TeamTab from '../../profile/tabs/team';
import ToDoTab from '../tabs/todo';
import OthersTab from '../../profile/tabs/others';

import ProfileTitle from '../../../../layout/profileTitle';

import { useGetOne } from '../../../../hooks';

import Filepond from "../../../filepond/";

const AdminPharmacyProfile = (props) => {
    const history = useHistory();

    const laboratories = useSelector(content => content.Laboratories.results);
    const { data, status } = useGetOne(`/api/v1/pharma/${props.params && props.params.id ? props.params.id : props && props.match && props.match.params.id ? props.match.params.id : null}?dashboard=true`, props.params && props.params.id ? props.params.id : props && props.match && props.match.params.id ? props.match.params.id : null, 'pharmacies');

    if (status === "error") history.push(`${process.env.PUBLIC_URL}/pages/errors/error404`);

    const [activeTab, setActiveTab] = useState('1');

    const [files, setFiles] = useState([]); //* FILEPOND
    const [modal, setModal] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);
    const [fileError, setFileError] = useState(null);

    const toggleModal = () => {
        setModal(true);
    }
    const closeModal = () => {
        setFiles([]); //* FILEPOND
        setModal(false);
    }
    const setCustomFiles = (files) => {
        setFiles(files) //* FILEPOND
    }

    const uploadProfilePhoto = () => {
        setFileLoading(true);

        let submitValues = new FormData();
        submitValues.append('profilePhoto[name]', files.name);
        submitValues.append('profilePhoto', files);

        axios.put(`/api/v1/pharma`, submitValues, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((result) => {
                if (result.status === 200) {
                    toast.success(`Uploaded feito com sucesso!`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setFileLoading(false);
                    closeModal();
                } else {
                    setFileLoading(false);
                    setFileError("Ocorreu um erro, tente mais tarde.");
                    setTimeout(() => {
                        setFileError(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setFileLoading(false);
                setFileError("Ocorreu um erro, tente mais tarde.");
                setTimeout(() => {
                    setFileError(null);
                }, 3000);
            });
    }

    return (
        <Fragment>
            <Container fluid={true}>
                {data ? (
                    <Fragment>
                        <ProfileTitle title={data.name} route={`${process.env.PUBLIC_URL}/administracao/farmacias`} />
                        <Container fluid={true}>
                            <div className="user-profile social-app-profile">
                                <Row>
                                    <Col sm="12">
                                        <Card className="hovercard text-center">
                                            <div className="cardheader socialheader" style={{ backgroundImage: `url(${data.profilePhoto && data.profilePhoto ? data.profilePhoto : '../../../assets/images/social-app/social-image.png'})` }} />
                                            <Button color="primary" size="xs" className="ml-2" onClick={() => toggleModal()} style={{ position: 'absolute', right: 20, top: 20 }}>
                                                <i className="fa fa-pencil" />
                                            </Button>
                                            {/* <div className="user-image">
                                                <div className="avatar">
                                                    <Media body alt="user" src={require("../../../assets/images/user/1.jpg")} />
                                                </div>
                                                <div className="icon-wrapper"><i className="icofont icofont-pencil-alt-5"></i></div>
                                                <ul className="share-icons">
                                                    <li><a className="social-icon bg-primary" href="#javascripts"><i className="fa fa-smile-o"></i></a></li>
                                                    <li><a className="social-icon bg-secondary" href="#javascripts"><i className="fa fa-wechat"></i></a></li>
                                                    <li><a className="social-icon bg-warning" href="#javascripts"><i className="fa fa-share-alt"></i></a></li>
                                                </ul>
                                            </div> */}
                                            <div className="info market-tabs p-0">
                                                <Nav tabs className="border-tab tabs-scoial">
                                                    <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                                            {"Atividade"}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                                            {"Equipa"}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <div className="user-designation"></div>
                                                        <div className="title">{data.name}</div>
                                                        <div className="desc mt-2">{data.ANF}</div>
                                                    </NavItem>
                                                    <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                                                            {"To Do List"}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === '4' ? 'active' : ''} onClick={() => setActiveTab('4')}>
                                                            {"Outros"}
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                                <TabContent activeTab={activeTab} className="tab-content">
                                    <TabPane tabId="1">
                                        <Activity data={data} />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <TeamTab data={data} laboratories={laboratories} />
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <ToDoTab data={data} />
                                    </TabPane>
                                    <TabPane tabId="4">
                                        <OthersTab data={data} />
                                    </TabPane>
                                </TabContent>
                            </div>
                        </Container>
                        <Modal isOpen={modal} toggle={() => closeModal}>
                            <ModalHeader toggle={() => closeModal}>
                                {"Upload Banner"}
                            </ModalHeader>
                            <ModalBody>
                                <Filepond
                                    name={"profilePhoto"}
                                    label={"Carregar Imagem"}
                                    multi={false}
                                    files={files}
                                    setFiles={setCustomFiles}
                                    content={`image`}
                                    imageEditInstantEdit
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={uploadProfilePhoto}>
                                    {fileLoading ? (
                                        <i className="fa fa-spin fa-spinner" />
                                    ) : fileError ? (
                                        fileError
                                    ) : (
                                        "Upload"
                                    )}
                                </Button>
                                <Button color="secondary" onClick={() => closeModal()}>
                                    {"Cancelar"}
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Fragment>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    );
}

export default AdminPharmacyProfile;