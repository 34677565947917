export const apiUrl = "/api/v1/accounts";
export const rqModel = "users";
export const pathname = `${process.env.PUBLIC_URL}/administracao/utilizadores`;

export const columns = [
    {
        name: "Username",
        selector: "username",
        sortable: true,
        center: true,
    },
    {
        name: "Função",
        selector: "role",
        sortable: false,
        center: true,
    },
    {
        name: "Nome",
        selector: "name",
        sortable: true,
        center: true,
    },
    {
        name: "Email",
        selector: "email",
        sortable: true,
        center: true,
    },
];

export const schema = [
    {
        colSize: "4",
        label: "E-mail",
        name: "email",
        id: "name",
        type: "email",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "4",
        label: "Primeiro Nome",
        name: "name.first",
        id: "name.first",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "4",
        label: "Último Nome",
        name: "name.last",
        id: "name.last",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "6",
        label: "Username",
        name: "username",
        id: "username",
        type: "text",
        required: true,
        requiredMessage:
            "Este campo é obrigatório e não pode conter caracteres especiais",
        pattern: /^[a-zA-Z0-9]{4,20}$/,
    },
    {
        colSize: "6",
        label: "Contacto",
        name: "phoneNumber",
        id: "phoneNumber",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "6",
        label: "User Telegram",
        name: "telegram_username",
        id: "telegram_username",
        type: "text",
        // required: true,
        // requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "6",
        label: "Role",
        name: "role",
        id: "role",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: false,
        optionLabel: "label",
        optionValue: "value",
        options: [
            {
                value: "AdminRole",
                label: "Administrador",
            },
            {
                value: "FarmaciaRole",
                label: "Farmácia",
            },
            {
                value: "FornecedorRole",
                label: "Fornecedor",
            },
            {
                value: "PrestadorRole",
                label: "Prestador",
            },
        ],
        controller: [
            {
                onSelect: "AdminRole",
                hide: "fornecedor_id",
                show: "AdminRole"
            },
            {
                onSelect: "FarmaciaRole",
                show: "FarmaciaRole-anf_code-qlikRefANF",
            },
            {
                onSelect: "FornecedorRole",
                show: "fornecedor_id-qlikRefLabCode-profile",
            },
            {
                onSelect: "PrestadorRole",
                show: null,
            },
        ],
    },
    {
        hidden: true,
        colSize: "6",
        label: "Posição",
        name: "mf_role",
        id: "AdminRole",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: false,
        optionLabel: "label",
        optionValue: "value",
        options: [
            {
                value: "adv",
                label: "Administrativo",
            },
            {
                value: "coo",
                label: "Coordenação",
            },
            {
                value: "neg",
                label: "Negociação",
            },
            {
                value: "opr",
                label: "Operações",
            },
            {
                value: "mkt",
                label: "Marketing",
            },
            {
                value: "adm",
                label: "Administração",
            },
            {
                value: "pc",
                label: "Pharmacy Coacher",
            },
            {
                value: "pcs",
                label: "Pharmacy Coach Senior",
            },
            {
                value: "pcj",
                label: "Pharmacy Coach Junior",
            },
            {
                value: "pcl",
                label: "Pharmacy Coach Líder",
            },
        ],
    },
    {
        hidden: true,
        colSize: "6",
        label: "Posição",
        name: "internal_role",
        id: "FarmaciaRole",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: false,
        optionLabel: "label",
        optionValue: "value",
        options: [
            {
                value: "dt",
                label: "Director Técnico",
            },
            {
                value: "fm",
                label: "Farmacêutico",
            },
            {
                value: "fmas",
                label: "Farmacêutico Adjunto Substituto",
            },
            {
                value: "tf",
                label: "Técnico de Farmácia",
            },
            {
                value: "tfa",
                label: "Ajudante Técnico de Farmácia",
            },
            {
                value: "aux",
                label: "Auxiliar",
            },
            {
                value: "ges",
                label: "Gestor",
            },
        ],
    },
    {
        hidden: true,
        colSize: "6",
        label: "Farmácia",
        name: "anf_code",
        id: "anf_code",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: false,
        options: null,
        optionLabel: "name",
        optionValue: "ANF",
        api: {
            route: "/api/v1/pharma",
            redux: "Pharmacies",
        },
    },
    {
        hidden: true,
        colSize: "6",
        label: "Farmácia Afectas",
        name: "qlikRefANF",
        id: "qlikRefANF",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: true,
        options: null,
        optionLabel: "name",
        optionValue: "ANF",
        api: {
            route: "/api/v1/pharma",
            redux: "Pharmacies",
        },
    },
    {
        hidden: true,
        colSize: "6",
        label: "Laboratório",
        name: "fornecedor_id",
        id: "fornecedor_id",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: false,
        options: null,
        optionLabel: "name",
        optionValue: "fornecedor_id",
        api: {
            route: "/api/v1/laboratories",
            redux: "Laboratories",
        },
    },
    {
        hidden: true,
        colSize: "6",
        label: "Laboratórios Afectos",
        name: "qlikRefLabCode",
        id: "qlikRefLabCode",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: true,
        options: null,
        optionLabel: "name",
        optionValue: "fornecedor_id",
        api: {
            route: "/api/v1/laboratories",
            redux: "Laboratories",
        },
    },
    {
        hidden: true,
        colSize: "12",
        label: "Perfil",
        name: "profile",
        id: "profile",
        type: "select",
        // required: true,
        // requiredMessage: "Este campo é obrigatório",
        multi: false,
        optionLabel: "label",
        optionValue: "value",
        options: [
            {
                value: "standard",
                label: "Standard",
            },
            {
                value: "representative",
                label: "Representador",
            },
        ],
    },

    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4",
      },
      {
        colSize: "1",
        label: "Analytics",
        name: "permissions.has_analytics_btn",
        id: "permissions.has_analytics_btn",
        type: "checkbox",
        className: "ml-20",
      },
      {
        colSize: "1",
        label: "Vê Push",
        name: "permissions.reads_inqueritos",
        id: "permissions.reads_inqueritos",
        type: "checkbox",
      },
      {
        colSize: "1",
        label: "Vê Farmácias",
        name: "permissions.pharmas_data",
        id: "permissions.pharmas_data",
        type: "checkbox",
      },
];
