import { Award, Folder, Package, Briefcase, Airplay, PieChart, ArrowUpCircle, Music } from 'react-feather'
export const MENUITEMS = [
    {
        menuTitle: null,
        menuContent: null,
        Items: [
           
            {
                title: 'A minha Farmácia', icon: ArrowUpCircle, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/farmacia`, title: 'Farmácia', type: 'link' },
                    { path: `https://www.dropbox.com/sh/sskk5vzioy0za35/AABAYBhXGAh5deMIpgpWdOWua?dl=0`, type: 'globalLink', title: 'Pasta Privada' },
                ]
            },


            { path: `${process.env.PUBLIC_URL}`, icon: Airplay, title: 'Dashboard', type: 'link' },
            { path: `https://qap-proxy.maisfarmacia.org/extensions/maisfarmacia/maisfarmacia.html`, icon: PieChart, title: 'Analytics', type: 'globalLink' },
            { path: `https://www.dropbox.com/sh/8hq31p4s6id134v/AACKoW8LefYY9D5qpF4wWfUQa?dl=0`, icon: Briefcase, title: 'Status Negociações', type: 'globalLink' },

            // { path: `${process.env.PUBLIC_URL}/administracao/produtos`, icon: Database, title: 'Produtos', type: 'link' },
            {
                title: 'Encomendas', icon: ArrowUpCircle, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/push/encomendas`, title: 'Push', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/push/desafio-flash`, title: 'Desafio Flash', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/push/direct`, title: 'maisfarmácia Direct', type: 'link' },
                ]
            },
            // { path: `${process.env.PUBLIC_URL}/visibilidade`, icon: Target, title: 'Visibilidade', type: 'link' },
            // { path: `${process.env.PUBLIC_URL}/alinhamento`, icon: AlignCenter, title: 'Alinhamento', type: 'link' },
        ]
    },
    {
        menuTitle: "Gestão Diária",
        menuContent: " ",
        Items: [
            {
                title: 'Comunicação', icon: Folder, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/centro/newsletters`, type: 'link', title: 'Newsletters' },
                    { path: `${process.env.PUBLIC_URL}/centro/noticias`, title: 'Notícias', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/centro/revista`, title: 'Revista+', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/centro/parcerias`, title: 'Parcerias Externas', type: 'link' },
                    { path: `https://docs.google.com/forms/d/e/1FAIpQLSdB2YfwoWxngO83DHbGaqa_EXtNxu40ogQJOxzqxoeMmBIAkA/viewform`, title: 'Ofertas de Emprego', type: 'globalLink'},
                ]
            },
            {
                title: 'Marketing', icon: Award, type: 'sub', active: false, children: [
                    { path: `https://formfacade.com/public/105414721870392670145/all/form/1FAIpQLScuiABunfgy9iaVTJ4WGNBSubR2l7jEq_ZNpMECu3Z93GGJ4A`, type: 'globalLink', title: 'Upload Mupi' },
                    { path: `https://www.dropbox.com/sh/lzgoet4n3wgsu1r/AADxlGUdwzECFXClhGb2Y4F2a?dl=0`, type: 'globalLink', title: 'Conteúdos Digitais' },
                    { path: `https://www.dropbox.com/sh/j3b1x233e14wid9/AAAN8LyysHG6-6W9XMKR1iuPa?dl=0`, type: 'globalLink', title: 'Gestão de Categorias' },
                    
                ]
            },

            {
                title: 'Musica Ambiente', icon: Music, type: 'sub', active: false, children: [
                    { path: `https://docs.google.com/forms/d/e/1FAIpQLSdsWj1Sv-2hxOHNAzmhnucpn3ckND03TUy0em79HMa8cImJSQ/viewform`, type: 'globalLink', title: 'Subscrição' },
                    { path: `https://app.waybox.pt/authentication/login`, type: 'globalLink', title: 'Acesso Portal' },
                    
                ]
            },
            {
                title: 'Formações', icon: Airplay, type: 'sub', active: false, children: [
                    { path: `https://forms.gle/cCwbWC3nAjmhgSGb7`, title: 'Inscrições be.smart', type: 'globalLink'},
                    { path: `https://formacao.maisfarmacia.org/`, title: 'Plataforma be.smart', type: 'globalLink' },
                   
                ]
            },
            
            {
                title: 'Loja maisFarmácia', icon: Package, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/loja/produtos`, title: 'Produtos', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/loja/renting`, title: 'Renting', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/loja/encomendas`, title: 'Encomendas', type: 'link' },
                    // { path: `${process.env.PUBLIC_URL}/loja/subscricoes`, title: 'Subscrições', type: 'link' },
                ]
            }
        ]
    }
]