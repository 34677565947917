import { Key, ArrowUpCircle, PieChart } from 'react-feather'
export const MENUITEMS = [
    {
        menuTitle: null,
        menuContent: null,
        Items: [
            {
                title: 'Administração', icon: Key, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/administracao/farmacias`, title: 'Farmácias', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/administracao/utilizadores`, title: 'Contas de Utilizador', type: 'link' },                    // { path: `${process.env.PUBLIC_URL}/administracao/fornecedores`, title: 'Fornecedores', type: 'link' },
                ]
            },
            { path: `https://qap-proxy.maisfarmacia.org/extensions/maisfarmacia/maisfarmacia.html`, icon: PieChart, title: 'Analytics', type: 'globalLink' },
            {
                title: 'Push', icon: ArrowUpCircle, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/push/encomendas`, title: 'Encomendas Push', type: 'link' },
                ]
            },
        ]
    },
    // {
    //     menuTitle: "Teste",
    //     menuContent: "Teste",
    //     Items: [
    //         {
    //             title: 'Marketing', icon: Award, type: 'sub', active: false, children: [
    //                 { path: `${process.env.PUBLIC_URL}/app/marketing/mf-music`, type: 'link', title: 'mfMusic' },
    //                 { path: `${process.env.PUBLIC_URL}/app/marketing/facebook`, type: 'link', title: 'Social' }
    //             ]
    //         },
    //         {
    //             title: 'Geral', icon: Folder, type: 'sub', active: false, children: [
    //                 { path: `${process.env.PUBLIC_URL}/app/centro/newsletter`, title: 'Newsletter', type: 'link' },
    //                 { path: `${process.env.PUBLIC_URL}/app/centro/noticias`, title: 'Notícias', type: 'link' },
    //                 { path: `${process.env.PUBLIC_URL}/app/centro/loja-maisfarmacia`, title: 'Loja maisFarmácia', type: 'link' },
    //                 { path: `${process.env.PUBLIC_URL}/app/centro/renting`, title: 'Renting', type: 'link' },
    //                 // { path: `${process.env.PUBLIC_URL}/app/centro/subscricoes`, title: 'Subscrições', type: 'link' },
    //             ]
    //         },
    //         {
    //             title: 'Outros', icon: MoreHorizontal, type: 'sub', active: false, children: [
    //                 { path: `${process.env.PUBLIC_URL}/app/link-analytics`, type: 'link', title: 'Link Analytics' },
    //                 { path: `${process.env.PUBLIC_URL}/app/social`, type: 'link', title: 'Social' },
    //                 // { path: `${process.env.PUBLIC_URL}/app/knowledge-base`, type: 'link', title: 'KnowledgeBase' },
    //             ]
    //         }
    //     ]
    // }
]